<template>
    <div class="">
        <header-main/>
        <v-container>
            <div class="article-header">
                <img :src="require('@/assets/img/png/article-header--sweets.png')">
                <img class="mobile" :src="require('@/assets/img/png/article-sweets.png')">
            </div>
            <div class="divider"></div>
            <div class="article-container">
                <div class="article-content">
                    <div class="title-mobile">
                        Почему мне хочется сладкого?
                    </div>
                    <div class="article-banner">
                        <span>Если вам постоянно хочется сладкого, стоит задуматься — причина может быть далеко не безобидная.</span>
                    </div>
                    <div class="text">
                        <p>Всем знакомо это чувство, когда катастрофически хочется сладкого. В эти моменты слабости кажется, что если сию минуту организм не получит дозу сахара, то быть беде.</p>
                        <br>
                        <p>Тому могут быть несколько причин, далеко не безобидных. Но определив ту, которая именно у вас вызывает тягу к сладкому, вы можете не только решить проблему, но и подправить здоровье.</p>

                        <h1>1. Нехватка углеводов</h1>
                        <p>Наряду с жирами и белками, они необходимы нашему организму для нормальной жизнедеятельности. Углеводы — главный источник энергии, в процессе переваривания они превращаются в глюкозу, которая «подпитывает» наш мозг, мышцы и улучшает настроение. Углеводы бывают двух видов: простые или быстрые (моносахариды) и сложные или медленные (полисахариды). На химическом уровне они отличаются строением: из названий ясно, что вторые устроены более сложным способом, а это значит, что организму понадобится больше времени, чтобы превратить их в глюкозу.</p>
                        <br>
                        <p>Быстрые углеводы (белый сахар и сладости, которые его содержат) провоцируют резкий скачок глюкозы в крови. Они моментально обеспечивают приток энергии, однако уже буквально через час последует такой же резкий спад уровня глюкозы, вернется чувство голода, может появиться усталость и раздражительность, а потребность в сладком только возрастет. Поэтому выбор стоит делать в пользу медленных углеводов — овощей и цельнозерновых круп. Они удовлетворяют потребность организма в углеводах, но при этом вы не рискуете набрать вес.</p>

                        <h1>2. Дефицит хрома</h1>
                        <p>Хром поддерживает нормальный уровень глюкозы в крови и снижает зависимость от сахара. А вот переизбыток сахара вымывает этот полезный минерал из организма. То есть при его недостатке и злоупотреблении сладким хрома становится еще меньше, а к сладкому тянет еще больше.</p>
                        <br>
                        <p>Восполнить недостаток хрома помогут грибы, бобовые, картофель, морепродукты, яйца, цветная капуста и брокколи.</p>

                        <h1>3. Стресс</h1>
                        <p>Последняя (но одна из самых распространенных) причина потребности в сладком — это стресс. Потребление сахара стимулирует выработку гормона счастья — серотонина. Он способствует снижению тревожности, помогает успокоить нервы и поднимает настроение. Но сахар — не единственный продукт, повышающий уровень серотонина.</p>
                        <br>
                        <p>Стимулировать выработку гормона счастья вам поможет белковая пища, зерновые продукты и картофель. Кроме того, с этой задачей отлично справляется какао. Приготовьте себе напиток из натурального какао-порошка и растительного молока без добавления сахара — это полезная альтернатива сладкому, которая удовлетворит желание съесть десерт и не принесет вреда фигуре.</p>

                    </div>
                </div>
                <div class="sidebar">
                    <banner-vertical/>
                    <div class="reference">
                        <div>Источник информации:</div>
                        <a target="_blank" href="https://www.thesymbol.ru/beauty/health/chto-sest-esli-postoyanno-hochetsya-sladkogo/">Что съесть, если постоянно хочется сладкого</a>
                    </div>
                </div>
            </div>
        </v-container>
        <footer-main/>
    </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import BannerVertical from "@/components/BannerVertical";

export default {
    name: "ArticleSweets",

    components: {
        "header-main": Header,
        "banner-vertical": BannerVertical,
        "footer-main": Footer
    },
}
</script>

<style lang="scss">
@import "../assets/scss/articles";

#app.dark {
    .article-container {
        .article-banner {
            background: #4C53FF;
        }
    }
}

</style>