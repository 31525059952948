<template>
    <v-container class="error-405">
        <h1>Ой! А вам сюда нельзя :(</h1>
        <h1>Ошибка 405</h1>
    </v-container>
</template>

<script>

export default {
    name: "Error405",
}
</script>

<style lang="scss">
#app {
    .error-405 {
        font-family: "Oswald-Bold", sans-serif;
        margin-top: 50px;
    }
}
</style>