<template>
    <div class="">
        <header-main/>
        <v-container>
            <div class="article-header">
                <img :src="require('@/assets/img/png/article-header--smoke.png')">
                <img class="mobile" :src="require('@/assets/img/png/article-smoke.png')">
            </div>
            <div class="divider"></div>
            <div class="article-container smoke">
                <div class="article-content">
                    <div class="title-mobile">
                        Ухудшает ли курение память?
                    </div>
                    <div class="article-banner">
                        <span>Курение буквально "разлагает" мозг, нарушая способность к обучению и рассуждению, а также ухудшая память.</span>
                    </div>
                    <div class="text">
                        <p>Учёные уже давно исследуют связь между курением и различными заболеваниями. Например, уже доказано, что сигареты приводят к деменции — слабоумию, которое характеризуется потерей памяти и снижением познавательных функций мозга. Исследования показывают, что курение затрагивает почти все области и структуры мозга, а вместе с тем кратковременную и долговременную память.</p>

                        <h1>Почему так происходит?
                        </h1>
                        <p>Сигаретный дым состоит из тысяч соединений, многие из которых токсичны для мозга, сердечно-сосудистой и лёгочной систем. Пагубная привычка увеличивает атрофию мозга (уменьшение и потеря объёма мозгового вещества). А это показатель старения мозга и снижения когнитивных способностей. У курильщиков также отмечается более высокий уровень биомаркеров окислительного стресса — они указывают на повреждение тканей мозга.</p>
                        <br>
                        <p>Как итог: кровообращение ухудшается, сосуды сужаются и мозг лишается кислорода. Это, в свою очередь, увеличивает производство белковых отложений в мозге, которые могут привести к болезни Альцгеймера. И не стоит забывать, что курение повышает риск множества других заболеваний, пагубно влияющих на структуры мозга.</p>

                        <h1>Значит, можно потерять память навсегда?</h1>
                        <p>Учёные говорят, что в первое время после отказа от сигарет когнитивные способности продолжают снижаться. Но если отказаться от пагубной привычки навсегда, то через 10 лет память вернётся к уровню никогда не куривших людей. Для этого важно тренировать память — упражнения как минимум отвлекают от желания вернуться к вредной привычке. Подойдут абсолютно любые техники: от изучения нового иностранного языка и запоминания стихов до настольных игр. Выбирайте то, что вам больше подходит.</p>
                        <br>
                        <p>И не забывайте, что отвыкнуть от привычки брать сигарету в руки под силу каждому. Это потребует немало времени, но первый результат вы увидите уже через 20 минут — за это время частота сердечных сокращений снижается и приходит в норму. Что уж говорить о более продолжительном отказе от вредной привычки. Обязательно следите за своим здоровьем.</p>
                    </div>
                </div>
                <div class="sidebar">
                    <banner-vertical/>
                    <div class="reference">
                        <div>Источник информации:</div>
                        <a target="_blank" href="https://www.championat.com/lifestyle/article-4573449-kak-kurenie-vliyaet-na-pamyat-cheloveka-i-rabotu-mozga-vozdejstvie-nikotina-na-mozg-i-ego-funkcii.html#:~:text=%D0%9E%D0%B1%D1%80%D0%B0%D0%B7%20%D0%B6%D0%B8%D0%B7%D0%BD%D0%B8%20%D0%B2%D0%BB%D0%B8%D1%8F%D0%B5%D1%82%20%D0%BD%D0%B0%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%83,%D0%BC%D1%8B%20%D0%B1%D1%8B%D1%81%D1%82%D1%80%D0%BE%20%D0%B0%D0%B4%D0%B0%D0%BF%D1%82%D0%B8%D1%80%D1%83%D0%B5%D0%BC%D1%81%D1%8F%20%D0%BA%20%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D1%8F%D0%BC">Что происходит с памятью, когда вы отказываетесь от курения?</a>
                    </div>
                </div>
            </div>
        </v-container>
        <footer-main/>
    </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import BannerVertical from "@/components/BannerVertical";

export default {
    name: "ArticleSmoke",

    components: {
        "header-main": Header,
        "banner-vertical": BannerVertical,
        "footer-main": Footer
    },
}
</script>

<style lang="scss">
@import "../assets/scss/articles";

#app {
    .article-container.smoke {
        .article-content {
            .article-banner {
                span {
                    width: 668px;

                    @media (max-width: 1263px) {
                        width: 468px;
                    }
                }
            }
        }
    }
}

#app.dark {
    .article-container.smoke {
        .article-banner {
            background: #FF3AC8;
        }
    }
}

</style>