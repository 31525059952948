<template>
    <div class="">
        <header-main/>
        <v-container>
            <div class="article-header">
                <img :src="require('@/assets/img/png/article-header--beer.png')">
                <img class="mobile" :src="require('@/assets/img/png/article-beer.png')">
            </div>
            <div class="divider"></div>
            <div class="article-container beer">
                <div class="article-content">
                    <div class="title-mobile">
                        Разрушает ли пиво мыщцы?
                    </div>
                    <div class="article-banner">
                        <span>Умеренное употребление алкоголя — не более 30–40 г этанола в день — не вредит вашей физической форме.</span>
                    </div>
                    <div class="text">
                        <p>Чтобы мышцы росли, нужно заниматься и употреблять достаточно белка. Тяжёлые тренировки запустят процесс наращивания мышц, а протеин послужит строительным материалом. Эти факторы не зависят от алкоголя — вы вполне можете посещать спортзал, есть белковую пищу и при этом каждый вечер употреблять спиртные напитки. Так делают многие спортсмены.</p>
                        <br>
                        <p>Однако белок и тренировки — это только базовые требования. На наращивание мускулатуры также влияет:</p>
                        <ul>
                            <li>способность аминокислот, строительных кирпичиков для мышц, проникать в клетки;</li>
                            <li>скорость создания и разрушения белка в тканях;</li>
                            <li>количество гормонов, которые помогают или мешают наращивать массу.</li>
                        </ul>
                        <br>
                        <p>Алкоголь влияет на все эти факторы, и ниже мы разберём, как именно.</p>

                        <h1>Влияние на синтез белка</h1>
                        <p>И высокие, и низкие дозы алкоголя угнетают создание белка в клетках мышц — синтез протеина.</p>
                        <br>
                        <p>Через час после приёма спиртного синтез протеина снижается на 23%, а через 24 часа — на 63%. Особенно сильно спирт угнетает этот процесс в мышечных волокнах II типа, которые быстро увеличиваются в размерах. То есть именно в тех тканях, на которые молятся все бодибилдеры.</p>
                        <br>
                        <p>Однако большинство исследований, посвященных влиянию алкоголя на мышцы, проводилось на мышах, которым давали огромные дозы этанола. Большое количество спиртного плохо влияет и на людей. Алкоголики, употребляющие более 100 г (2 л пива, 250 г водки) в день, часто страдают от миопатии — разрушения мышечной ткани. У одной-двух третей пьющих наблюдается атрофия мышц, что приводит к частым падениям и сложностям даже при ходьбе.</p>
                        <br>
                        <p>Что касается умеренных доз алкоголя, неизвестно, влияют ли они на синтез протеина у людей.</p>

                        <h1>Влияние на тестостерон</h1>
                        <p>Тестостерон — один из важнейших гормонов для поддержания хорошей физической формы, наращивания мышц и уменьшения жировой прослойки.</p>
                        <br>
                        <p>Алкоголь снижает уровень тестостерона, но для значительных изменений нужно употреблять спиртное часто и помногу. Если каждый день на протяжении трёх недель выпивать по полторы-две бутылки пива, тестостерон у мужчин снизится всего на 6,8%, а у женщин останется без изменений.</p>
                        <br>
                        <p>Более масштабные пьянки отражаются на уровне гормонов гораздо быстрее: уже через 16 часов после приёма 120 г этанола (это более пяти банок пива, 300 г водки или почти целая бутылка вина) тестостерон у мужчин снижается на 23%.</p>
                        <br>
                        <p>Меньшие дозы алкоголя практически не влияют на гормоны. Однократное распитие полутора банок пива или 150 г водки после тренировки не влияют на уровень тестостерона, лютеинизирующего гормона и кортикотропина.</p>
                        <br>
                        <p>Другое дело — действительно тяжёлые тренировки на силу или выносливость и большие дозы алкоголя. 200–300 г крепкого напитка после таких нагрузок значительно снизят восстановление и ослабляют мышцы.</p>

                        <h1>Влияние на инсулин</h1>
                        <p>Инсулин просто необходим для наращивания массы. Этот гормон запускает синтез протеина в рибосомах и препятствует катаболизму — распаду белка. Кроме того, он помогает глюкозе и аминокислотам проникать из крови в мышечные ткани.</p>
                        <br>
                        <p>Чем выше чувствительность клеток к инсулину, тем лучше он снабжает их глюкозой для запаса гликогена и аминокислотами для построения мускулатуры.</p>
                        <br>
                        <p>Доказано, что умеренное употребление алкоголя улучшает чувствительность к инсулину. Но чтобы она повысилась, нужно пить спиртное в небольших количествах и периодически. Однократный приём малых доз алкоголя не меняет уровень инсулина.</p>

                        <h1>Как пить, чтобы не навредить фигуре</h1>
                        <p>Единственное требование — умеренность. 30–40 г этанола в день не вредят наращиванию мышечной массы и не приводят к накоплению жира. В пересчёте на напитки это будет 700–900 г пива крепостью 4,5%, 300–400 г. вина крепостью 10%, 75–100 г. водки.</p>
                        <br>
                        <p>Небольшие дозы спиртного могут даже принести пользу. Несмотря на то что и тренировки, и употребление алкоголя по отдельности способны вызывать окислительный стресс, их совмещение имеет обратный эффект (конечно, здесь не имеются в виду занятия «под мухой»).</p>
                        <br>
                        <p>Этанол в сочетании с тренировками снижает перекисное окисление липидов, которое вызывает окислительный стресс и повышает риск атеросклероза. Так что, если вы не можете отказаться от алкоголя и переживаете за своё сердце — займитесь спортом.</p>
                        <br>
                        <p>Продолжайте выпивать свой бокал пива или вина после тренировки, если это расслабляет вас. Но не забывайте, что алкоголь не должен заменять вам полноценные приёмы пищи с большим количеством белка. Без диетического протеина ваши результаты будут более чем скромными.</p>


                    </div>
                </div>
                <div class="sidebar">
                    <banner-vertical/>
                    <div class="reference">
                        <div>Источник информации:</div>
                        <a target="_blank" href="https://lifehacker.ru/pit-i-nakachat-myshcy/">Нужно ли бросать пить, если вы хотите накачать мышцы</a>
                    </div>
                </div>
            </div>
        </v-container>
        <footer-main/>
    </div>
</template>

<script>
import Header from "@/components/Header";
import BannerVertical from "@/components/BannerVertical";
import Footer from "@/components/Footer";

export default {
    name: "ArticleBeer",
    components: {
        "header-main": Header,
        "banner-vertical": BannerVertical,
        "footer-main": Footer
    },
}
</script>

<style lang="scss">
#app {
    .article-container.beer {
        .article-content {
            .article-banner {
                span {
                    width: 668px;

                    @media (max-width: 1263px) {
                        width: 468px;
                    }
                }
            }
        }
    }
}

#app.dark {
    .article-container.beer {
        .article-banner {
            background: #FF8421;
        }
    }
}
</style>